module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"PuLvKeiL0UC18xIZCT0z3TG4hJXUtk2A","devKey":"PuLvKeiL0UC18xIZCT0z3TG4hJXUtk2A","trackPage":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vinofiles | Vinomofo","short_name":"Vinofiles","start_url":"/articles/","background_color":"#ffffff","theme_color":"#131314","display":"minimal-ui","icon":"src/images/vm-512x512.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bbf3b32d6ac1ad15ed1e3ac4622f66d8"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
